<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div class="auth-bg">
        <div class="authentication-box">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <Authorization />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Authorization from "@/components/Authorization";
export default {
  components: {
    Authorization
  }
};
</script>